<template>
  <div class="main row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Danh sách phân khúc khách hàng'">
        <template v-slot:toolbar>
          <div class="row">
            <!-- <div v-show="checkPermission('CUSTOMER_INSERT')" class="ml-3"> -->
            <router-link to="/customer-segment/add-customer-segment">
              <b-button size="sm" variant="primary">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                <b>Thêm mới</b>
              </b-button>
            </router-link>
            <!-- </div> -->
          </div>
        </template>
        <template v-slot:preview>
          <b-row>
            <b-col cols="12">
              <b-table
                :items="listSegments"
                :fields="fields"
                bordered
                hover
                :busy="onLoading"
              >
                <template v-slot:table-busy>
                  <vcl-table
                    :speed="5"
                    :animate="true"
                    :columns="10"
                  ></vcl-table>
                </template>
                <!-- <template v-slot:cell(userCreate)="row">
                  <span class="sumary-text">
                    {{ row.item.userCreateSegment.employee.fullName }}
                    {{ row.item.userCreateSegment.employee.code }}
                  </span>
                </template> -->
                <!-- <template v-slot:cell(upgradeCost)="row">
                  <span class="text-center d-block">
                    {{ convertPrice(row.item.upgradeCost) + ' đ' }}
                  </span>
                </template> -->
                <template v-slot:cell(name)="row">
                  <a v-bind:href="getLinkDetail(row.item)">
                    <b>
                      {{ row.item.name }}
                    </b>
                  </a>
                  <!-- <span class="sumary-text">
                    {{ row.item.description }}
                  </span> -->
                </template>
                <template v-slot:cell(description)="row">
                  <span class="sumary-text">
                    {{ row.item.description }}
                  </span>
                </template>
                <template v-slot:cell(customer)="row">
                  <a
                    v-bind:href="getLinkDetail(row.item)"
                    class="badge badge-primary"
                    v-if="row.item.customer"
                  >
                    Xem {{ row.item.customer }} hồ sơ khách hàng
                  </a>

                  <span class="sumary-text text-center" v-else>
                    <i
                      v-if="!isLoading || indexLoading !== row.index"
                      @click="fetchCustomer(row.index, row.item.id)"
                      class="fa-solid fas fa-redo-alt "
                      style="font-size: 1rem; padding-right: 0px"
                    ></i>

                    <b-spinner
                      v-else-if="indexLoading === row.index"
                      class="mr-3"
                      label="Spinning"
                    ></b-spinner>
                  </span>
                </template>
                <template v-slot:cell(status)="row">
                  <div class="text-center" v-if="row.item.status">
                    <span class="btn btn-success">
                      Bật
                    </span>
                  </div>
                  <div v-else class="text-center">
                    <span class="btn btn-danger">
                      Tắt
                    </span>
                  </div>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-dropdown size="sm" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem"
                        class="flaticon2-settings pr-0"
                      ></i>
                    </template>
                    <b-dropdown-item @click="editItem(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="fas fa-eye icon-color"
                        ></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-pen icon-color"
                        ></i>
                        &nbsp; Cập nhật
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-show="checkPermission('USER_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <div class="d-flex justify-content-end">
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                  </template>
                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>
                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>
                  <template v-slot:last-text>
                    <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                  </template>
                </b-pagination-nav>
              </b-col>
            </div>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { VclTable } from 'vue-content-loading';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

import {
  // formatDate,
  //   convertPrice,
  //   makeToastSuccess,
  makeToastFaile,
} from '@/utils/common';

export default {
  data() {
    return {
      listSegments: [],
      listCustomers: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          thStyle: { textAlign: 'center', width: '5%' },
          class: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên',
          thStyle: { textAlign: 'center', width: '15%' },
        },
        // {
        //   key: 'upgradeCost',
        //   label: 'Tiền cần tiêu dùng',
        //   thStyle: { textAlign: 'center', width: '20%' },
        //   formatter: (value) => {
        //     return `${convertPrice(value)} đ`;
        //   },
        // },

        {
          key: 'description',
          label: 'Mô tả',
          thStyle: { textAlign: 'center', width: '30%' },
          formatter: (value) => {
            return value;
          },
        },

        {
          key: 'customer',
          label: 'Khách hàng',
          thStyle: { textAlign: 'center', width: '20%' },
          tdClass: 'text-center',
        },
        {
          key: 'userCreateSegment',
          label: 'Người tạo',
          thStyle: { textAlign: 'center', width: '20%' },
          formatter: (value) => {
            return `${value.employee.fullName} - ${value.employee.code}`;
          },
        },

        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { textAlign: 'center', width: '10%' },
        },
        { key: 'actions', label: '', class: 'text-center' },
      ],
      page: 1,
      totalItem: 0,
      numberOfPage: 1,
      isLoading: false,
      indexLoading: null,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    // datePicker,
  },
  mounted(){
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Khách hàng', route: '/customers' },
      { title: 'Phân khúc khách hàng' },
    ]);
  },
  methods: {
    fetchData: function() {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;

      const params = {
        limit: 10,
        page: this.page,
      };
      ApiService.query('rfm/list', {
        params: params,
      }).then(({ data }) => {
        if (data.status) {
          this.listSegments = [];
          const dataReq = data.data;
          this.totalItem = dataReq.total;
          this.listSegments = dataReq.dataset;
          this.numberOfPage = dataReq.pages;
        } else {
          makeToastFaile(data.message);
        }
        this.onLoading = false;
      });
    },
    fetchCustomer: async function(index, id) {
      if (this.isLoading) return;
      this.isLoading = true;
      this.indexLoading = index;
      try {
        await ApiService.query(
          `/rfm/${id}/customer?page=1&limit=999999999`,
        ).then((response) => {
          const data = response.data.data;
          this.listSegments[index]['customer'] = data.length;
          this.isLoading = false;
          this.indexLoading = null;
        });
      } catch (error) {
        this.isLoading = false;
        this.indexLoading = null;
      } finally {
        this.isLoading = false;
        this.indexLoading = null;
      }
    },
    editItem: function(item) {
      this.$router.push({
        name: 'update-customer-segment',
        query: { id: item.id },
      });
    },
    getLinkDetail: function(item) {
      return `#/customer-segment/update-customer-segment?id=${item.id}&currentTab=2`;
    },
  },

  created() {
    this.fetchData();
  },
};
</script>
